import {request} from '@/utils/axios'

// 弹框
// 企业列表
export const companyList = (params) => {
    return request({
        method: 'get',
        url: sysUrl+'/business/company/list',
        params
    })
}
// 企业列表 根据标签查询
export const listByLable = (params) => {
    return request({
        method: 'get',
        url: sysUrl+'/business/company/listByLable',
        params
    })
}
// 地区列表
export const cityList = (params) => {
    return request({
        method: 'get',
        url: sysUrl+'/system/city/list',
        params
    })
}
// 企业详情
export const articleDetail = (params) => {
    return request({
        method: 'get',
        url: sysUrl+'/business/company/'+params,
    })
}
// 企业种植情况详情
export const plantInfo = (params) => {
    return request({
        method: 'get',
        url: sysUrl+'/web/plantInfo/list',
        params
    })
}
// 产业概况-精品品牌
export const brandInfo = (params,headers) => {
    return request({
        method: 'get',
        url: sysUrl+'/web/brandInfo/list',
        params,
        headers
    })
}
// 供应列表
export const supplyList = (params) => {
    return request({
        method: 'get',
        url: sysUrl+'/web/CompanyRecoveryInfo/getRecoInfoByPIdAndCodeAndComTypeId',
        params,
    })
}
// 全省概况-绿色有机-企业列表
export const listByBrandInfo = (params) => {
    return request({
        method: 'get',
        url: sysUrl+'/business/company/listByBrandInfo',
        params,
    })
}
// 产业概况-服务企业-详情
export const getCompanysByLableid = (params) => {
    return request({
        method: 'get',
        url: sysUrl+'/web/comAndLabRelation/getCompanysByLableid',
        params,
    })
}
// 专家服务-专家详情
export const expertInfo = (params,headers) => {
    return request({
        method: 'get',
        url: thingsApi+'/expertv2/v1/api/expert/info',
        params,
        headers
    })
}
// 专家服务-专家连线发送消息
export const sendTransmissionToSingle = (params,headers) => {
    return request({
        method: 'get',
        url: thingsApi+'/userserver/v1/api/push/sendTransmissionToSingle',
        params,
        headers
    })
}