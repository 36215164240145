<template>
    <div class="detail">
        <base-dialog 
            width="1000px" 
            label="专家详情"
            :show-close="true"
            v-show="dialogFlag"
            @closeDialog="closeDialog"
        >
            <div class="wrap">
                <div class="info">
                    <div class="img_left">
                        <img :src="infoObj.user&&infoObj.user.headPic?(wjImageUrl+infoObj.user.headPic):portraitFemale" alt="">
                    </div>
                    <div class="right">
                        <div class="top">
                            <div class="user">
                                <p>姓名：<span>{{infoObj.name||''}}</span></p>
                                <p>{{infoObj.duties||''}}</p>
                            </div>
                            <div class="splx" @click="videoLink()">视频连线</div>
                        </div>
                        <ul class="bottom">
                            <!-- <li>
                                <p>关注</p>
                                <span>40<i>个</i></span>
                            </li> -->
                            <li>
                                <p>问答</p>
                                <span>{{infoObj.answers||0}}<i>个</i></span>
                            </li>
                            <li>
                                <p>成果</p>
                                <span>{{infoObj.techresults||0}}<i>个</i></span>
                            </li>
                            <li>
                                <p>动态</p>
                                <span>{{infoObj.activities||0}}<i>个</i></span>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="content">
                    <ul class="tab_head">
                        <li class="active">简介</li>
                        <!-- <li>问答</li>
                        <li>成果</li>
                        <li>动态</li> -->
                    </ul>
                    <div class="tab_body">
                        <keep-alive>
                            <el-scrollbar style="height:100%;">
                                <component :is="currPage" :infoObj="infoObj"></component>
                            </el-scrollbar>
                        </keep-alive>
                    </div>
                </div>
            </div>
        </base-dialog>
    </div>
</template>

<script>
import BaseDialog from '@/components/dialog/baseDialog' //弹框
import Intro from '@/components/expert/intro' //简介
import {expertInfo,sendTransmissionToSingle} from '@/api/dialog' //接口
export default {
    components:{BaseDialog,Intro},
    data(){
        return {
            currPage:'Intro',//
            dialogFlag:false,
            infoObj:{},//
            wjImageUrl,
            portraitFemale:require('./../../assets/images/portrait_female.png'),//默认头像-女
        }
    },
    methods:{
        expertInfo(Id){//专家详情
            expertInfo({id:Id},{autp:2}).then(res=>{
                // console.log('专家详情')
                // console.log(JSON.parse(JSON.stringify(res.data)))
                this.infoObj=res.data||{}
            })
        },
        sendTransmissionToSingle(){//专家连线发送消息
            let headImg=this.infoObj.user&&this.infoObj.user.headPic?(this.wjImageUrl+this.infoObj.user.headPic):this.portraitFemale
            let roomid=Math.floor(Math.random() * (9999999999 - 1000000000) ) + 1000000000
            let content={
                type:"video",
                user:"系统管理员",
                headpic:headImg,
                roomid
            }
            // console.log(JSON.parse(JSON.stringify(content)))
            sendTransmissionToSingle({
                name:'expert',
                alias:this.infoObj.phone,//专家手机号码
                content:JSON.stringify(content),
            },{autp:2}).then(res=>{
                // console.log('专家连线发送消息')
                // console.log(JSON.parse(JSON.stringify(res.data)))
                let href=`https://trace.hbyzc.com.cn/expertdata/expert-connection.html?pName=${this.infoObj.name}&roomid=${roomid}`
                window.open(href,'_blank')
            })
        },
        closeDialog(){//关闭弹框
            this.dialogFlag=false
        },
        show(val){//关闭弹框
            this.dialogFlag=true
            this.expertInfo(val)
        },
        videoLink(val){//视频连线
            this.sendTransmissionToSingle()
        },
    }
}
</script>

<style lang='scss' scoped>
    .detail{
        // border: 1px solid #f00;
        .wrap{
            // border: 3px solid #0f0;
            height: 100%;
            box-sizing: border-box;
            padding: 20px 35px;
            display: flex;
            flex-direction: column;
            .info{
                display: flex;
                align-items: center;
                .img_left{
                    width: 160px;
                    height: 195px;
                    flex: none;
                    background: url('~@/assets/images/zxjz_video_border.png') no-repeat center/100% 100%;
                    padding: 5px;
                    box-sizing: border-box;
                    // border: 1px solid #0f0;
                    flex: none;
                    img{
                        width: 100%;
                        height: 100%;
                    }
                }
                .right{
                    // border: 1px solid #f00;
                    flex: 1;
                    margin-left: 40px;
                    .top{
                        display: flex;
                        align-items: center;
                        // border: 1px solid #0f0;
                        .user{
                            width: 250px;
                            // border: 1px solid #f00;
                            flex: none;
                            p:nth-child(1){
                                font-size: 28px;
                                span{
                                    color: #02cafd;
                                }
                            }
                            p:nth-child(2){
                                font-size: 18px;
                                margin-top: 15px;
                            }
                        }
                        .splx{
                            // border: 1px solid #f00;
                            font-size: 20px;
                            color: #0da0e5;
                            width: 187px;
                            height: 49px;
                            line-height: 49px;
                            text-align: center;
                            padding-left: 40px;
                            box-sizing: border-box;
                            cursor: pointer;
                            font-weight: 700;
                            background: url('~@/assets/images/dialog/splx.png') no-repeat center/100% 100%;
                        }
                    }
                    .bottom{
                        display: flex;
                        align-items: center;
                        justify-content: space-around;
                        margin-top: 30px;
                        li{
                            width: 165px;
                            height: 50px;
                            background: url('~@/assets/images/dialog/wlwsb_bg.png') no-repeat center/100% 100%;
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            padding: 0 20px;
                            box-sizing: border-box;
                            p{
                                font-size: 18px;
                            }
                            span{
                                font-size: 30px;
                                color: #00FCFF;
                                i{
                                    font-size: 16px;
                                }
                            }
                        }
                    }
                }
            }
            .content{
                margin-top: 20px;
                // border: 2px solid #f00;
                flex: 1;
                display: flex;
                flex-direction: column;
                overflow: hidden;
                .tab_head{
                    display: flex;
                    justify-content: center;
                    padding-top: 15px;
                    padding-bottom: 25px;
                    box-sizing: border-box;
                    li{
                        cursor: pointer;
                        width: 145px;
                        height: 36px;
                        margin: 0 10px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-size: 20px;
                        background: url('~@/assets/images/dialog/qyxx_tab.png') no-repeat center/100% 100%;
                    }
                    .active{
                        background: url('~@/assets/images/dialog/qyxx_tab_active.png') no-repeat center/100% 100%;
                    }
                }
                .tab_body{
                    // border: 2px solid #0f0;
                    flex: 1;
                    overflow: hidden;
                }
            }
        }
    }
</style>