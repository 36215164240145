<template>
    <div class="list">
        <base-dialog 
            width="1200px" 
            label="专家列表"
            v-show="dialogFlag"
            :show-close="true"
            @closeDialog="closeDialog"
        >
        <div class="wrap">
            <el-form :inline="true" class="myform">
                <el-form-item label="">
                    <el-input class="pub_input" v-model="form.name" placeholder="姓名" clearable></el-input>
                </el-form-item>
                <el-form-item label="">
                    <el-input class="pub_input" v-model="form.special" placeholder="专业" clearable></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button @click="listData()" class="btn" type="primary" icon="el-icon-search"></el-button>
                </el-form-item>
            </el-form>
            <div class="table_list">
                <el-table :data="tableData" stripe class="pub_table" height="100%">
                    <el-table-column type="index" label="序号" width="60" align="center"></el-table-column>
                    <el-table-column prop="post" label="岗位" width="100" align="center"></el-table-column>
                    <el-table-column prop="name" label="专家" width="100" align="center"></el-table-column>
                    <el-table-column prop="special" label="专业" width="150" align="center"></el-table-column>
                    <el-table-column prop="duties" label="职称" width="150" align="center"></el-table-column>
                    <el-table-column prop="unit" label="工作单位" min-width="100" align="center"></el-table-column>
                    <el-table-column label="详情" width="100" align="center">
                        <template slot-scope="scope">
                            <span class="look" @click="lookDetail(scope.row.id)">查看</span>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <el-pagination
                class="pub_laypage" 
                :page-size="15"
                @current-change="listData"
                :current-page.sync="form.page"
                prev-text="上一页"
                next-text="下一页"
                :pager-count="11"
                layout="prev, pager, next,total"
                :total="total">
            </el-pagination>
        </div>
        </base-dialog>
        <!-- 专家详情 -->
        <expert-detail ref="expertDetail"></expert-detail>
    </div>
</template>

<script>
import BaseDialog from '@/components/dialog/baseDialog' //弹框
import ExpertDetail from '@/components/expert/detail' //专家详情
import {listData} from '@/api/zjfw' //接口
export default {
    components:{BaseDialog,ExpertDetail},
    data(){
        return {
            form:{
                name:'',//姓名
                special:'',//专业
                page:1,
            },
            tableData:[],
            total:0,
            dialogFlag:false,
        }
    },
    methods:{
        listData(page){//专家列表
            this.form.page=page||1
            listData(this.form,{autp:2}).then(res=>{
                // console.log('专家列表')
                // console.log(JSON.parse(JSON.stringify(res.data.data)))
                this.tableData=res.data.data||[]
                this.total=res.data.count||0
            }).catch(()=>{
                this.tableData=[]
                this.total=0
            })
        },
        closeDialog(){//关闭弹框
            this.dialogFlag=false
            // this.$emit('closeDialog')
        },
        show(obj){//显示
            this.dialogFlag=true
            if (obj) {        
                Object.assign(this.form,obj)
            }
            this.listData()
        },
        lookDetail(val){//查看详情
            this.$refs.expertDetail.show(val)
        },
    }
}
</script>

<style lang='scss' scoped>
    .list{
        // border: 1px solid #f00;
        .wrap{
            // border: 3px solid #0f0;
            height: 100%;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            padding: 20px;
            .myform{
                flex: none;
                .btn{
                    font-size: 20px;
                    padding: 0 20px;
                    height: 40px;
                }
            }
            .table_list{
                flex: 1;
                overflow: hidden;
                margin-bottom: 10px;
                .look{
                    color:#00ffff;
                    font-size: 16px;
                    cursor: pointer;
                }
            }
            .pub_laypage{
                flex: none;
            }
        }
    }
</style>