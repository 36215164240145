import {request} from '@/utils/axios'

//专家服务-知识库
export const knowbases = (params) => {
    return request({
        method: 'get',
        url: thingsApi+'/expertv2/v1/api/websitePlatform/science/knowbases',
        params
    })
}
//专家服务-视频讲座
export const videos = (params) => {
    return request({
        method: 'get',
        url: thingsApi+'/expertv2/v1/api/websitePlatform/science/videos',
        params
    })
}
//专家服务-首席
export const getChief = (params) => {
    return request({
        method: 'get',
        url: thingsApi+'/expertv2/v1/api/websitePlatform/teams/getChief',
        params
    })
}
//专家服务-成员列表
export const listExperts = (params) => {
    return request({
        method: 'get',
        url: thingsApi+'/expertv2/v1/api/websitePlatform/teams/listExperts',
        params
    })
}
//专家服务-专家列表
export const listData = (params,headers) => {
    return request({
        method: 'get',
        url: thingsApi+'/expertv2/v1/api/expert/listData',
        params,
        headers
    })
}