<template>
  <div class="zjfw">
    <div class="left">
      <!-- 专家分析 -->
      <div class="pub_border top">
        <div class="pub_title">专家分析</div>
        <div class="content">
          <div class="zjfx_title">
            <h3>{{ zjfxObj.name }}</h3>
            <p>
              <span>{{ zjfxObj.value }}</span>人
            </p>
          </div>
          <div class="chart" id="Zjfx"></div>
        </div>
      </div>
      <!-- 专家列表 -->
      <div class="pub_border bottom">
        <div class="pub_title">专家列表</div>
        <div class="content">
          <ul v-infinite-scroll="expertReLoad" infinite-scroll-disabled="zjDisabled" infinite-scroll-distance="10">
            <li v-for="item in expertData" :key="item.id" @click="lookDetail(item.id)">
              <div class="img_left">
                <img :src="item.imgs ? (wjImageUrl + item.imgs) : portraitFemale" alt />
                <p>先进个人</p>
              </div>
              <ul class="info_right">
                <li>
                  <span>姓名:</span>
                  {{ item.name }}
                </li>
                <li>
                  <span>职称:</span>
                  {{ item.duties }}
                </li>
                <li>
                  <span>专业:</span>
                  {{ item.fields }}
                </li>
                <li>
                  <span>单位:</span>
                  {{ item.unit }}
                </li>
              </ul>
            </li>
            <!-- <li>
                            <div class="img_left">
                                <img src="./../../assets/images/portrait_female.png" alt="">
                                <p>先进个人</p>
                            </div>
                            <ul class="info_right">
                                <li><span>姓名:</span>狄政敏</li>
                                <li><span>职称:</span>正高级</li>
                                <li><span>专业:</span>种植专业</li>
                                <li><span>单位:</span>省农科院</li>
                            </ul>
            </li>-->
          </ul>
          <p v-if="zjLoading">加载中...</p>
          <p v-if="zjNoMore">没有更多了</p>
        </div>
      </div>
    </div>
    <div class="center">
      <!-- 露地蔬菜创新团队 -->
      <div class="pub_border top">
        <div class="pub_title">
          露地蔬菜创新团队
          <span @click="lookExpert('f0da74a918c844f983933d37c8b7173032')" class="look_more">查看更多</span>
        </div>
        <div class="content">
          <ul class="list_left">
            <li>
              <p>首席</p>
              <span>
                {{ ldObj.sx }}
                <i>人</i>
              </span>
            </li>
            <li>
              <p>岗位专家</p>
              <span>
                {{ ldObj.gwzj }}
                <i>人</i>
              </span>
            </li>
            <li>
              <p>推广站长</p>
              <span>
                {{ ldObj.tgzz }}
                <i>人</i>
              </span>
            </li>
          </ul>
          <div class="img_right">
            <div class="pub_carousel">
              <img @click="arrowClick('left')" src="./../../assets/images/left_arrow.png" class="arrow left_arrow" alt />
              <el-carousel ref="cardShow" height="205px" arrow="never" indicator-position="outside">
                <el-carousel-item v-for="item in ldcxCpd" :key="item.id + item.name">
                  <div class="inner" @click="lookDetail(item.id)">
                    <div class="img_left">
                      <img :src="item.imgs ? (wjImageUrl + item.imgs) : portraitFemale" alt />
                    </div>
                    <ul class="info_right">
                      <li>
                        <span>专家姓名:</span>
                        {{ item.name }}
                      </li>
                      <li>
                        <span>从业年限:</span>5年
                      </li>
                      <li>
                        <span>技术方向:</span>
                        {{ item.special }}
                      </li>
                      <li>
                        <span>职称:</span>
                        {{ item.post }}
                      </li>
                    </ul>
                  </div>
                </el-carousel-item>
              </el-carousel>
              <img @click="arrowClick('right')" src="./../../assets/images/right_arrow.png" class="arrow right_arrow"
                alt />
            </div>
          </div>
        </div>
      </div>
      <!-- 知识库 -->
      <div class="pub_border bottom">
        <div class="pub_title">知识库</div>
        <div class="content">
          <!-- <el-scrollbar style="height:100%;overflow-y: auto;" v-infinite-scroll="reLoad"> -->
          <ul class="list" v-infinite-scroll="reLoad" infinite-scroll-disabled="disabled" infinite-scroll-distance="10">
            <li v-for="item in zskData" :key="item.id">
              <h3 @click="lookArticle(item)">{{ item.name }}</h3>
              <p>小型西瓜又称小西瓜、迷你西瓜。其瓜形小巧美观，一般单瓜重1.O--2.5kg。肉质细嫩，汁多酥脆，中心糖和边糖含量高，风味口感极好，携带食用方便。因此</p>
            </li>
            <!-- <li>
                                <h3>小型西瓜新品种及其高效栽培技术</h3>
                                <p>小型西瓜又称小西瓜、迷你西瓜。其瓜形小巧美观，一般单瓜重1.O--2.5kg。肉质细嫩，汁多酥脆，中心糖和边糖含量高，风味口感极好，携带食用方便。因此</p>
                            </li>
                            <li>
                                <h3>小型西瓜新品种及其高效栽培技术</h3>
                                <p>小型西瓜又称小西瓜、迷你西瓜。其瓜形小巧美观，一般单瓜重1.O--2.5kg。肉质细嫩，汁多酥脆，中心糖和边糖含量高，风味口感极好，携带食用方便。因此</p>
            </li>-->
          </ul>
          <p v-if="loading">加载中...</p>
          <p v-if="noMore">没有更多了</p>
          <!-- </el-scrollbar> -->
        </div>
      </div>
    </div>
    <div class="right">
      <!-- 设施蔬菜创新团队 -->
      <div class="pub_border top">
        <div class="pub_title">
          设施蔬菜创新团队
          <span @click="lookExpert('f0da74a918c844f983933d37c8b717303')" class="look_more">查看更多</span>
        </div>
        <div class="content">
          <div class="img_right">
            <div class="pub_carousel">
              <img @click="ssArrowClick('left')" src="./../../assets/images/left_arrow.png" class="arrow left_arrow"
                alt />
              <el-carousel ref="ssCardShow" height="205px" arrow="never" indicator-position="outside">
                <el-carousel-item v-for="item in sscxCpd" :key="item.id + item.name">
                  <div class="inner" @click="lookDetail(item.id)">
                    <div class="img_left">
                      <img :src="item.imgs ? (wjImageUrl + item.imgs) : portraitFemale" alt />
                    </div>
                    <ul class="info_right">
                      <li>
                        <span>专家姓名:</span>
                        {{ item.name }}
                      </li>
                      <li>
                        <span>从业年限:</span>5年
                      </li>
                      <li>
                        <span>技术方向:</span>
                        {{ item.special }}
                      </li>
                      <li>
                        <span>职称:</span>
                        {{ item.post }}
                      </li>
                    </ul>
                  </div>
                </el-carousel-item>
              </el-carousel>
              <img @click="ssArrowClick('right')" src="./../../assets/images/right_arrow.png" class="arrow right_arrow"
                alt />
            </div>
          </div>
          <ul class="list_left">
            <li>
              <p>首席</p>
              <span>
                {{ ssObj.sx }}
                <i>人</i>
              </span>
            </li>
            <li>
              <p>岗位专家</p>
              <span>
                {{ ssObj.gwzj }}
                <i>人</i>
              </span>
            </li>
            <li>
              <p>推广站长</p>
              <span>
                {{ ssObj.tgzz }}
                <i>人</i>
              </span>
            </li>
          </ul>
        </div>
      </div>
      <!-- 视频回放 -->
      <div class="pub_border bottom">
        <div class="pub_title">视频回放</div>
        <div class="content">
          <ul class="collapse_list" v-infinite-scroll="spReLoad" infinite-scroll-disabled="spDisabled"
            infinite-scroll-distance="10">
            <li class="collapse_item" v-for="item in videoList" :key="item.id">
              <div class="collapse_head" @click="tog(item)">
                <p @click="lookVideo(item)">{{ item.name }}</p>
                <span>
                  <i>讲师：</i>
                  {{ item.username }}
                </span>
              </div>
              <div class="collapse_body" v-show="item.isOpen">
                <video controls>
                  <source :src="wjImageUrl + item.videourl" type="video/mp4" />您的浏览器不支持Video标签。
                </video>
              </div>
            </li>
          </ul>
          <p v-if="spLoading">加载中...</p>
          <p v-if="spNoMore">没有更多了</p>
        </div>
      </div>
    </div>
    <!-- 专家列表 -->
    <expert-list ref="expertList"></expert-list>
    <!-- 专家详情 -->
    <expert-detail ref="expertDetail"></expert-detail>
    <!-- 视频详情 -->
    <video-detail ref="videoDetail"></video-detail>
    <!-- 图文详情 -->
    <article-detail ref="articleDetail"></article-detail>
  </div>
</template>

<script>
import 'echarts-gl'
import * as api from '@/api/zjfw' //接口
// import { wjImageUrl } from '@/utils/config'
import { ifError } from 'assert'
import ExpertList from '@/components/expert/list' //专家列表
import ExpertDetail from '@/components/expert/detail' //专家详情
import VideoDetail from '@/components/videoDetail' //视频详情
import ArticleDetail from '@/components/articleDetail' //图文详情
export default {
  name: 'Zjfw',
  components: { ExpertList, ExpertDetail, VideoDetail, ArticleDetail },
  data() {
    return {
      videoList: [
        //视频回放
        // {
        //     id:1,
        //     title:'设施番茄基质栽培关键技术',
        //     date:'2022-06-19',
        //     isOpen:true
        // },
        // {
        //     id:2,
        //     title:'设施番茄基质栽培关键技术',
        //     date:'2022-06-19',
        //     isOpen:false
        // },
        // {
        //     id:3,
        //     title:'设施番茄基质栽培关键技术',
        //     date:'2022-06-19',
        //     isOpen:false
        // },
        // {
        //     id:4,
        //     title:'设施番茄基质栽培关键技术',
        //     date:'2022-06-19',
        //     isOpen:false
        // },
      ],
      spCurrPage: 1, //视频回放-当前页
      spTotalPage: '', //视频回放
      spLoading: false, //视频回放

      isUnique: true, //是否只打开一个
      Zjfx: '', //专家分析
      zjfxObj: {
        //专家分析
        name: '',
        value: '',
      },
      zskData: [], //知识库
      currPage: 1, //当前页
      totalPage: '',
      loading: false,
      wjImageUrl,
      portraitFemale: require('./../../assets/images/portrait_female.png'), //默认头像-女
      ldSxData: [], //露地首席
      ldCyData: [], //露地成员
      ldObj: {
        //露地统计
        sx: 0, //首席
        gwzj: 0, //岗位专家
        tgzz: 0, //推广站长
      },
      ssSxData: [], //设施首席
      ssCyData: [], //设施成员
      ssObj: {
        //设施统计
        sx: 0, //首席
        gwzj: 0, //岗位专家
        tgzz: 0, //推广站长
      },
      expertData: [], //专家列表
      zjCurrPage: 1, //专家列表-当前页
      zjTotalPage: '', //专家列表
      zjLoading: false, //专家列表
      expertFlag: false, //专家列表弹框
      detailFlag: false, //查看专家详情
      // videoFlag:false,//视频弹框
      // articleFlag:false,//图文详情弹框
    }
  },
  computed: {
    noMore() {
      // 当起始页数大于等于总页数时停止加载
      return this.currPage >= this.totalPage
    },
    disabled() {
      return this.loading || this.noMore
    },
    spNoMore() {
      //视频回放
      // 当起始页数大于等于总页数时停止加载
      return this.spCurrPage >= this.spTotalPage
    },
    spDisabled() {
      //视频回放
      return this.spLoading || this.spNoMore
    },
    zjNoMore() {
      //专家列表
      // 当起始页数大于等于总页数时停止加载
      return this.zjCurrPage >= this.zjTotalPage
    },
    zjDisabled() {
      //专家列表
      return this.zjLoading || this.zjNoMore
    },
    ldcxCpd() {
      //露地创新团队
      return [...this.ldSxData, ...this.ldCyData]
    },
    sscxCpd() {
      //露地创新团队
      return [...this.ssSxData, ...this.ssCyData]
    },
  },
  mounted() {
    this.knowbases()
    this.videos(1)
    this.listData()
    // 设施蔬菜
    this.getChief('ss')
    this.listExperts('ss')
    // 露地蔬菜
    this.getChief('ld')
    this.listExperts('ld')

    this.ZjfxInit()
  },
  methods: {
    listData() {
      //专家列表
      api
        .listData({
          token: window.sessionStorage.token,
          autp: 2,
          special: '农学',
          status: '通过',
          teamid:'f0da74a918c844f983933d37c8b717303,f0da74a918c844f983933d37c8b7173032',
          page: this.zjCurrPage,
        })
        .then((res) => {
          // console.log('专家列表')
          // console.log(JSON.parse(JSON.stringify(res.data)))
          this.zjTotalPage = res.data.count
          this.expertData = [...this.expertData, ...res.data.data]
          this.zjLoading = false
        })
        .catch(() => {
          this.zjLoading = false
        })
    },
    expertReLoad() {
      //专家列表无限加载
      // console.log('专家列表无限加载')
      //滑到底部时进行加载
      this.zjLoading = true
      setTimeout(() => {
        this.zjCurrPage += 1 //滚动条到底时，页码自增 1
        this.listData() //调用接口
      }, 500)
    },
    getChief(val) {
      //首席
      // 设施蔬菜
      // f0da74a918c844f983933d37c8b717303
      // 露地蔬菜
      // f0da74a918c844f983933d37c8b7173032
      api
        .getChief({
          token: window.sessionStorage.token,
          teamid:
            val == 'ss'
              ? 'f0da74a918c844f983933d37c8b717303'
              : 'f0da74a918c844f983933d37c8b7173032',
          autp: 2,
        })
        .then((res) => {
          // console.log('首席')
          // console.log(JSON.parse(JSON.stringify(res.data)))
          if (val == 'ss') {
            //设施蔬菜
            this.ssCyData = res.data || []
            this.ssObj.sx = this.ssCyData.length
          } else {
            //露地蔬菜
            this.ldCyData = res.data || []
            this.ldObj.sx = this.ldCyData.length
          }
        })
    },
    listExperts(val) {
      //成员列表
      // 设施蔬菜
      // f0da74a918c844f983933d37c8b717303
      // 露地蔬菜
      // f0da74a918c844f983933d37c8b7173032
      api
        .listExperts({
          token: window.sessionStorage.token,
          teamid:
            val == 'ss'
              ? 'f0da74a918c844f983933d37c8b717303'
              : 'f0da74a918c844f983933d37c8b7173032',
        })
        .then((res) => {
          // console.log('成员列表')
          // console.log(JSON.parse(JSON.stringify(res.data)))
          if (val == 'ss') {
            //设施蔬菜
            this.ssSxData = res.data
            let gwzjNum = 0
            let tgzzNum = 0
            res.data.forEach((item) => {
              if (item.membertype == '岗位专家') {
                gwzjNum++
              } else if (item.membertype == '推广站长') {
                tgzzNum++
              }
            })
            this.ssObj.gwzj = gwzjNum
            this.ssObj.tgzz = tgzzNum
          } else {
            //露地蔬菜
            this.ldSxData = res.data
            let gwzjNum = 0
            let tgzzNum = 0
            res.data.forEach((item) => {
              if (item.membertype == '岗位专家') {
                gwzjNum++
              } else if (item.membertype == '推广站长') {
                tgzzNum++
              }
            })
            this.ldObj.gwzj = gwzjNum
            this.ldObj.tgzz = tgzzNum
          }
        })
    },
    knowbases() {
      //知识库
      api
        .knowbases({
          token: window.sessionStorage.token,
          type1: '蔬菜',
          page: this.currPage,
        })
        .then((res) => {
          // console.log('知识库')
          // console.log(JSON.parse(JSON.stringify(res.data)))
          this.totalPage = res.data.totalPages
          this.zskData = [...this.zskData, ...res.data.data]
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    videos(val) {
      //视频回放
      api
        .videos({
          token: window.sessionStorage.token,
          type1: '蔬菜',
          page: this.spCurrPage,
        })
        .then((res) => {
          // console.log('视频回放')
          // console.log(JSON.parse(JSON.stringify(res.data)))
          this.spTotalPage = res.data.totalPages
          let arr = res.data.data.map((item, i) => {
            if (val && i == 0) {
              item.isOpen = true
            } else {
              item.isOpen = false
            }
            return item
          })

          this.videoList = [...this.videoList, ...arr]
          this.spLoading = false
        })
        .catch(() => {
          this.spLoading = false
        })
    },
    spReLoad() {
      //视频回放无限加载
      // console.log('视频回放无限加载')
      //滑到底部时进行加载
      this.spLoading = true
      setTimeout(() => {
        this.spCurrPage += 1 //滚动条到底时，页码自增 1
        this.videos() //调用接口
      }, 500)
    },
    ZjfxInit() {
      //专家分析
      this.Zjfx = this.$echarts.init(document.getElementById('Zjfx'))
      let zjfxList = [
        {
          name: '研究员',
          value: 78,
          itemStyle: {
            color: '#21F6C1',
          },
        },
        {
          name: '教授',
          value: 153,
          itemStyle: {
            color: '#FFB00A',
          },
        },
        {
          name: '岗位专家',
          value: 241,
          itemStyle: {
            color: '#00C0FF',
          },
        },
      ]
      // 传入数据生成 option
      let option = this.getPie3D(zjfxList, 0.56)
      this.Zjfx.setOption(option)
      if (zjfxList.length != 0) {
        this.zjfxObj.name = zjfxList[0].name
        this.zjfxObj.value = zjfxList[0].value
      }
      // 监听鼠标事件，实现饼图选中效果（单选），近似实现高亮（放大）效果。
      let selectedIndex = ''
      let hoveredIndex = ''
      // 监听点击事件，实现选中效果（单选）
      this.Zjfx.on('click', (params) => {
        this.zjfxObj.name = params.seriesName
        for (let i = 0; i < zjfxList.length; i++) {
          if (params.seriesName == zjfxList[i].name) {
            this.zjfxObj.value = zjfxList[i].value
          }
        }
        // 从 option.series 中读取重新渲染扇形所需的参数，将是否选中取反。
        let isSelected = !option.series[params.seriesIndex].pieStatus.selected
        let isHovered = option.series[params.seriesIndex].pieStatus.hovered
        let k = option.series[params.seriesIndex].pieStatus.k
        let startRatio = option.series[params.seriesIndex].pieData.startRatio
        let endRatio = option.series[params.seriesIndex].pieData.endRatio

        // 如果之前选中过其他扇形，将其取消选中（对 option 更新）
        if (selectedIndex !== '' && selectedIndex !== params.seriesIndex) {
          option.series[selectedIndex].parametricEquation =
            this.getParametricEquation(
              option.series[selectedIndex].pieData.startRatio,
              option.series[selectedIndex].pieData.endRatio,
              false,
              false,
              k,
              option.series[selectedIndex].pieData.value
            )
          option.series[selectedIndex].pieStatus.selected = false
        }

        // 对当前点击的扇形，执行选中/取消选中操作（对 option 更新）
        option.series[params.seriesIndex].parametricEquation =
          this.getParametricEquation(
            startRatio,
            endRatio,
            isSelected,
            isHovered,
            k,
            option.series[selectedIndex].pieData.value
          )
        option.series[params.seriesIndex].pieStatus.selected = isSelected

        // 如果本次是选中操作，记录上次选中的扇形对应的系列号 seriesIndex
        isSelected ? (selectedIndex = params.seriesIndex) : null

        // 使用更新后的 option，渲染图表
        this.Zjfx.setOption(option)
      })
      // 监听 mouseover，近似实现高亮（放大）效果
      this.Zjfx.on('mouseover', (params) => {
        // 准备重新渲染扇形所需的参数
        let isSelected
        let isHovered
        let startRatio
        let endRatio
        let k

        // 如果触发 mouseover 的扇形当前已高亮，则不做操作
        if (hoveredIndex === params.seriesIndex) {
          return

          // 否则进行高亮及必要的取消高亮操作
        } else {
          // 如果当前有高亮的扇形，取消其高亮状态（对 option 更新）
          if (hoveredIndex !== '') {
            // 从 option.series 中读取重新渲染扇形所需的参数，将是否高亮设置为 false。
            isSelected = option.series[hoveredIndex].pieStatus.selected
            isHovered = false
            startRatio = option.series[hoveredIndex].pieData.startRatio
            endRatio = option.series[hoveredIndex].pieData.endRatio
            k = option.series[hoveredIndex].pieStatus.k

            // 对当前点击的扇形，执行取消高亮操作（对 option 更新）
            option.series[hoveredIndex].parametricEquation =
              this.getParametricEquation(
                startRatio,
                endRatio,
                isSelected,
                isHovered,
                k,
                option.series[hoveredIndex].pieData.value
              )
            option.series[hoveredIndex].pieStatus.hovered = isHovered

            // 将此前记录的上次选中的扇形对应的系列号 seriesIndex 清空
            hoveredIndex = ''
          }

          // 如果触发 mouseover 的扇形不是透明圆环，将其高亮（对 option 更新）
          if (params.seriesName !== 'mouseoutSeries') {
            // 从 option.series 中读取重新渲染扇形所需的参数，将是否高亮设置为 true。
            isSelected = option.series[params.seriesIndex].pieStatus.selected
            isHovered = true
            startRatio = option.series[params.seriesIndex].pieData.startRatio
            endRatio = option.series[params.seriesIndex].pieData.endRatio
            k = option.series[params.seriesIndex].pieStatus.k

            // 对当前点击的扇形，执行高亮操作（对 option 更新）
            option.series[params.seriesIndex].parametricEquation =
              this.getParametricEquation(
                startRatio,
                endRatio,
                isSelected,
                isHovered,
                k,
                option.series[params.seriesIndex].pieData.value + 5
              )
            option.series[params.seriesIndex].pieStatus.hovered = isHovered

            // 记录上次高亮的扇形对应的系列号 seriesIndex
            hoveredIndex = params.seriesIndex
          }

          // 使用更新后的 option，渲染图表
          this.Zjfx.setOption(option)
        }
      })

      // 修正取消高亮失败的 bug
      this.Zjfx.on('globalout', () => {
        if (hoveredIndex !== '') {
          // 从 option.series 中读取重新渲染扇形所需的参数，将是否高亮设置为 true。
          isSelected = option.series[hoveredIndex].pieStatus.selected
          isHovered = false
          k = option.series[hoveredIndex].pieStatus.k
          startRatio = option.series[hoveredIndex].pieData.startRatio
          endRatio = option.series[hoveredIndex].pieData.endRatio

          // 对当前点击的扇形，执行取消高亮操作（对 option 更新）
          option.series[hoveredIndex].parametricEquation =
            getParametricEquation(
              startRatio,
              endRatio,
              isSelected,
              isHovered,
              k,
              option.series[hoveredIndex].pieData.value
            )
          option.series[hoveredIndex].pieStatus.hovered = isHovered

          // 将此前记录的上次选中的扇形对应的系列号 seriesIndex 清空
          hoveredIndex = ''
        }

        // 使用更新后的 option，渲染图表
        this.Zjfx.setOption(option)
      })
    },
    ZjfxReload() {
      //专家分析重载
      this.Zjfx.setOption({
        series: [
          {
            data: [],
          },
        ],
      })
    },
    lookExpert(Id) {
      //查看专家列表
      let obj = {
        teamid: Id,
      }
      this.$refs.expertList.show(obj)
    },
    lookDetail(val) {
      //查看专家详情
      this.$refs.expertDetail.show(val)
    },
    lookVideo(v) {
      //查看视频
      let obj = {
        title: v.name, //标题
        username: v.username, //讲师
        hits: v.hits, //播放量
        videosource: v.videosource, //单位
        screatetime: v.screatetime, //时间
        coursedesc: v.coursedesc, //课程介绍
        coursesyllabus: v.coursesyllabus, //课程大纲
        videourl: v.videourl, //视频
      }
      this.$refs.videoDetail.show(obj)
    },
    lookArticle(row) {
      //查看文章
      let obj = {
        title: row.name,
        createtime: row.screatetime,
        content: row.content,
      }
      this.$refs.articleDetail.show(obj)
    },
    // 生成扇形的曲面参数方程，用于 series-surface.parametricEquation
    getParametricEquation(startRatio, endRatio, isSelected, isHovered, k) {
      // 计算
      let midRatio = (startRatio + endRatio) / 2

      let startRadian = startRatio * Math.PI * 2
      let endRadian = endRatio * Math.PI * 2
      let midRadian = midRatio * Math.PI * 2

      // 如果只有一个扇形，则不实现选中效果。
      if (startRatio === 0 && endRatio === 1) {
        isSelected = false
      }

      // 通过扇形内径/外径的值，换算出辅助参数 k（默认值 1/3）
      k = typeof k !== 'undefined' ? k : 1 / 3

      // 计算选中效果分别在 x 轴、y 轴方向上的位移（未选中，则位移均为 0）
      let offsetX = isSelected ? Math.cos(midRadian) * 0.1 : 0
      let offsetY = isSelected ? Math.sin(midRadian) * 0.1 : 0

      // 计算高亮效果的放大比例（未高亮，则比例为 1）
      let hoverRate = isHovered ? 1.05 : 1

      // 返回曲面参数方程
      return {
        u: {
          min: -Math.PI,
          max: Math.PI * 3,
          step: Math.PI / 32,
        },

        v: {
          min: 0,
          max: Math.PI * 2,
          step: Math.PI / 20,
        },

        x: function (u, v) {
          if (u < startRadian) {
            return (
              offsetX +
              Math.cos(startRadian) * (1 + Math.cos(v) * k) * hoverRate
            )
          }
          if (u > endRadian) {
            return (
              offsetX + Math.cos(endRadian) * (1 + Math.cos(v) * k) * hoverRate
            )
          }
          return offsetX + Math.cos(u) * (1 + Math.cos(v) * k) * hoverRate
        },

        y: function (u, v) {
          if (u < startRadian) {
            return (
              offsetY +
              Math.sin(startRadian) * (1 + Math.cos(v) * k) * hoverRate
            )
          }
          if (u > endRadian) {
            return (
              offsetY + Math.sin(endRadian) * (1 + Math.cos(v) * k) * hoverRate
            )
          }
          return offsetY + Math.sin(u) * (1 + Math.cos(v) * k) * hoverRate
        },

        z: function (u, v) {
          if (u < -Math.PI * 0.5) {
            return Math.sin(u)
          }
          if (u > Math.PI * 2.5) {
            return Math.sin(u)
          }
          return Math.sin(v) > 0 ? 1 : -1
        },
      }
    },
    // 生成模拟 3D 饼图的配置项
    getPie3D(pieData, internalDiameterRatio) {
      let series = []
      let sumValue = 0
      let startValue = 0
      let endValue = 0
      let legendData = []
      let k =
        typeof internalDiameterRatio !== 'undefined'
          ? (1 - internalDiameterRatio) / (1 + internalDiameterRatio)
          : 1 / 3

      // 为每一个饼图数据，生成一个 series-surface 配置
      for (let i = 0; i < pieData.length; i++) {
        sumValue += pieData[i].value

        let seriesItem = {
          name:
            typeof pieData[i].name === 'undefined'
              ? `series${i}`
              : pieData[i].name,
          type: 'surface',
          parametric: true,
          wireframe: {
            show: false,
          },
          pieData: pieData[i],
          pieStatus: {
            selected: false,
            hovered: false,
            k: k,
          },
        }

        if (typeof pieData[i].itemStyle != 'undefined') {
          let itemStyle = {}

          typeof pieData[i].itemStyle.color != 'undefined'
            ? (itemStyle.color = pieData[i].itemStyle.color)
            : null
          typeof pieData[i].itemStyle.opacity != 'undefined'
            ? (itemStyle.opacity = pieData[i].itemStyle.opacity)
            : null

          seriesItem.itemStyle = itemStyle
        }
        series.push(seriesItem)
      }

      // 使用上一次遍历时，计算出的数据和 sumValue，调用 getParametricEquation 函数，
      // 向每个 series-surface 传入不同的参数方程 series-surface.parametricEquation，也就是实现每一个扇形。
      for (let i = 0; i < series.length; i++) {
        endValue = startValue + series[i].pieData.value

        series[i].pieData.startRatio = startValue / sumValue
        series[i].pieData.endRatio = endValue / sumValue
        series[i].parametricEquation = this.getParametricEquation(
          series[i].pieData.startRatio,
          series[i].pieData.endRatio,
          true,
          false,
          0.25
        )

        startValue = endValue

        legendData.push(series[i].name)
      }

      // 补充一个透明的圆环，用于支撑高亮功能的近似实现。
      series.push({
        name: 'mouseoutSeries',
        type: 'surface',
        parametric: true,
        wireframe: {
          show: false,
        },
        clockwise: false,
        itemStyle: {
          opacity: 0,
          color: 'rgba(18,236,252,.2)',
        },
        parametricEquation: {
          u: {
            min: 0,
            max: Math.PI * 2,
            step: Math.PI / 20,
          },
          v: {
            min: 0,
            max: Math.PI,
            step: Math.PI / 1.4,
          },
          x: function (u, v) {
            return Math.sin(v) * Math.sin(u) + Math.sin(u)
          },
          y: function (u, v) {
            return Math.sin(v) * Math.cos(u) + Math.cos(u)
          },
          z: function (u, v) {
            return Math.cos(v) > 0 ? 0.1 : -0.1
          },
        },
      })

      // 准备待返回的配置项，把准备好的 legendData、series 传入。
      let option = {
        //animation: false,
        legend: {
          bottom: 20,
          itemWidth: 14,
          itemHeight: 14,
          textStyle: {
            color: '#fff',
            fontSize: 16,
            rich: {
              num1: {
                color: '#32EEFF',
                fontSize: 18,
                // padding: [0, 0, 0, 10],
                // width: 60,
                // borderColor:'#f00',
                // borderWidth: 2 ,
                // align:'right',
              },
            },
          },
          formatter: function (name, i) {
            const data = option.series[0].pieData
            let val = ''
            for (let i = 0; i < option.series.length; i++) {
              if (name == option.series[i].name) {
                val = option.series[i].pieData.value
                break
              }
            }
            return `${name} {num1|${val}人}`
          },
          data: legendData,
        },
        // tooltip: {
        //     formatter: params => {
        //         if (params.seriesName !== 'mouseoutSeries') {
        //             return `${params.seriesName}<br/><span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:${params.color};"></span>${option.series[params.seriesIndex].pieData.value}`;
        //         }
        //     }
        // },
        xAxis3D: {
          min: -1,
          max: 1,
        },
        yAxis3D: {
          min: -1,
          max: 1,
        },
        zAxis3D: {
          min: -1,
          max: 1,
        },
        grid3D: {
          show: false,
          boxHeight: 10,
          top: '-3%',
          left: '0',
          bottom: '50%',
          viewControl: {
            //3d效果可以放大、旋转等，请自己去查看官方配置
            alpha: 20,
            beta: 40,
            rotateSensitivity: 0,
            zoomSensitivity: 0,
            panSensitivity: 0,
            autoRotate: false,
            //   autoRotateSpeed: 5,
            //   autoRotateAfterStill: 10
          },
        },
        series: series,
      }
      return option
    },
    arrowClick(val) {
      //上下切换
      if (val === 'right') {
        this.$refs.cardShow.next()
      } else {
        this.$refs.cardShow.prev()
      }
    },
    ssArrowClick(val) {
      //设施上下切换
      if (val === 'right') {
        this.$refs.ssCardShow.next()
      } else {
        this.$refs.ssCardShow.prev()
      }
    },
    tog(obj) {
      //视频回放切换
      if (obj.isOpen) {
        //打开中
        obj.isOpen = false
      } else {
        //折叠中
        if (this.isUnique) {
          //只打一个
          this.videoList.forEach((item) => {
            item.isOpen = false
          })
          // obj.isOpen=true
        } else {
          //可以打开多个
        }
        obj.isOpen = true
      }
    },
    reLoad() {
      //知识库无限加载
      // console.log('知识库无限加载')
      //滑到底部时进行加载
      this.loading = true
      setTimeout(() => {
        this.currPage += 1 //滚动条到底时，页码自增 1
        this.knowbases() //调用接口
      }, 500)
    },
  },
}
</script>
<style lang='scss' scoped>
.zjfw {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  padding: 0 30px;

  .list_left {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 175px;

    // border: 1px solid #f00;
    >li {
      width: 100%;
      height: 25px;
      background: url('~@/assets/images/cxtd_bg.png') no-repeat center/100% 100%;
      display: flex;
      justify-content: space-between;
      align-items: end;
      padding: 10px 30px;
      box-sizing: border-box;

      p {
        font-size: 16px;
      }

      span {
        font-size: 24px;

        i {
          font-size: 14px;
        }
      }
    }

    >li:nth-child(1) {
      span {
        color: #ffc71b;
      }
    }

    >li:nth-child(2) {
      span {
        color: #00deff;
      }
    }

    >li:nth-child(3) {
      span {
        color: #0cfed9;
      }
    }
  }

  .img_right {
    flex: 1;
    margin-left: 10px;
    padding-bottom: 15px;

    // border: 1px solid #f00;
    .pub_carousel {
      padding-top: 15px;

      .inner {
        // border: 1px solid #0f0;
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        padding: 10px 20px;
        cursor: pointer;

        .img_left {
          width: 140px;
          height: 180px;
          flex: none;
          background: url('~@/assets/images/zxjz_video_border.png') no-repeat center/100% 100%;
          padding: 5px;
          box-sizing: border-box;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .info_right {
          margin-left: 20px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          flex: 1;
          overflow: hidden;
          height: 100%;

          >li {
            font-size: 20px;
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;

            span {
              display: inline-block;
              color: #02cafd;
              width: 90px;
            }
          }
        }
      }
    }
  }

  .left {
    // border: 2px solid #0f0;
    width: 410px;
    flex: none;
    display: flex;
    flex-direction: column;

    .top {
      height: 360px;
      flex: none;
      // border: 2px solid #0f0;
      display: flex;
      flex-direction: column;

      .content {
        flex: 1;
        position: relative;

        .zjfx_title {
          position: absolute;
          left: 50%;
          top: 20px;
          transform: translateX(-50%);
          // border: 1px solid #f00;
          text-align: center;
          width: 195px;

          >h3 {
            font-size: 18px;
            height: 27px;
            line-height: 27px;
            background: url('~@/assets/images/xsdt_bg.png') no-repeat center/100% 100%;
          }

          >p {
            font-size: 16px;
            margin-top: 10px;

            span {
              font-size: 22px;
            }
          }
        }

        .chart {
          height: 100%;
          width: 100%;
        }
      }
    }

    .bottom {
      // border: 2px solid #0f0;
      margin-top: 20px;
      flex: 1;
      display: flex;
      flex-direction: column;
      overflow: hidden;

      .content {
        padding: 30px;
        flex: 1;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        overflow: hidden;

        // border: 1px solid #f00;
        >ul {
          // border: 1px solid #0f0;
          flex: 1;
          overflow-y: auto;

          >li {
            margin-top: 30px;
            width: 350px;
            height: 160px;
            background: url('~@/assets/images/zjlb_bg.png') no-repeat center/100% 100%;
            display: flex;
            padding: 15px 20px;
            box-sizing: border-box;
            cursor: pointer;

            .img_left {
              width: 105px;
              height: 130px;
              flex: none;
              background: url('~@/assets/images/zxjz_video_border.png') no-repeat center/100% 100%;
              padding: 5px;
              box-sizing: border-box;
              position: relative;

              img {
                width: 100%;
                height: 100%;
              }

              p {
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                background-color: rgba(0, 42, 77, 0.9);
                height: 28px;
                text-align: center;
                line-height: 28px;
              }
            }

            .info_right {
              margin-left: 20px;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              flex: 1;
              overflow: hidden;

              >li {
                font-size: 18px;
                width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;

                span {
                  color: #02cafd;
                }
              }
            }
          }

          li:first-child {
            margin-top: 0;
          }
        }

        p {
          font-size: 16px;
          text-align: center;
        }

        ::-webkit-scrollbar {
          display: none;
        }
      }
    }
  }

  .center {
    // border: 2px solid #f0f;
    flex: 1;
    margin: 0 10px;
    display: flex;
    flex-direction: column;

    .top {
      height: 360px;
      flex: none;
      display: flex;
      flex-direction: column;

      .pub_title {
        display: flex;
        justify-content: space-between;
      }

      .content {
        display: flex;
        flex: 1;
        // border: 2px solid #f0f;
        padding: 30px 25px 25px;
        box-sizing: border-box;
      }
    }

    .bottom {
      // border: 2px solid #ff0;
      margin-top: 20px;
      flex: 1;
      display: flex;
      flex-direction: column;
      overflow: hidden;

      .content {
        flex: 1;
        padding: 20px 40px;
        box-sizing: border-box;
        overflow: hidden;
        //::v-deep.el-scrollbar__wrap{
        //     // overflow-x: hidden;
        // }
        display: flex;
        flex-direction: column;

        .list {
          // margin-top: -25px;
          // border: 1px solid #f00;
          // height: 100%;
          // height: 300px;
          overflow-y: auto;
          flex: 1;

          >li {
            font-size: 16px;
            background: url('~@/assets/images/zsk_border.png') no-repeat center/100% 100%;
            padding: 20px 25px;
            box-sizing: border-box;
            margin-top: 20px;

            h3 {
              // font-size: 16px;
              font-weight: normal;
              cursor: pointer;
            }

            p {
              margin-top: 15px;
              color: #02cafd;
              line-height: 1.5;
              // font-size: 16px;
            }
          }
        }

        p {
          font-size: 16px;
          text-align: center;
        }

        ::-webkit-scrollbar {
          display: none;
        }
      }
    }
  }

  .right {
    // border: 2px solid #ff0;
    width: 680px;
    flex: none;
    display: flex;
    flex-direction: column;

    .top {
      // border: 2px solid #f00;
      height: 360px;
      flex: none;
      display: flex;
      flex-direction: column;

      .pub_title {
        display: flex;
        justify-content: space-between;
      }

      .content {
        display: flex;
        flex: 1;
        // border: 2px solid #f0f;
        padding: 20px 25px 25px;
        box-sizing: border-box;

        .img_right {
          margin-left: 0;
          margin-right: 10px;

          .pub_carousel {
            .inner {
              padding: 10px 10px;
            }
          }
        }

        .list_left {
          width: 170px;
        }
      }
    }

    .bottom {
      // border: 2px solid #ff0;
      margin-top: 20px;
      flex: 1;
      display: flex;
      flex-direction: column;
      overflow: hidden;

      .content {
        flex: 1;
        padding: 20px 40px;
        box-sizing: border-box;
        // border: 2px solid #f00;
        overflow: hidden;

        .collapse_list {
          // border: 2px solid #0f0;
          overflow-y: auto;
          height: 100%;

          .collapse_item {
            margin-top: 10px;

            .collapse_head {
              background: url('~@/assets/images/zxjz_text_bg.png') no-repeat center/100% 100%;
              display: flex;
              align-items: center;
              justify-content: space-between;
              height: 42px;
              padding: 0 20px;
              box-sizing: border-box;
              cursor: pointer;

              p {
                font-size: 18px;
              }

              span {
                font-size: 16px;

                i {
                  color: #02cafd;
                }
              }
            }

            .collapse_body {
              background: url('~@/assets/images/zxjz_video_border.png') no-repeat center/100% 100%;
              padding: 5px;
              box-sizing: border-box;
              height: 240px;

              video {
                width: 100%;
                height: 100%;
              }
            }
          }

          li:first-child {
            margin-top: 0;
          }
        }

        p {
          font-size: 16px;
          text-align: center;
        }

        ::-webkit-scrollbar {
          display: none;
        }
      }
    }
  }

  .look_more {
    cursor: pointer;
    font-size: 14px;
    color: #3699ff;
    width: 100px;
    margin-right: 20px;
    text-align: center;
    position: relative;
    bottom: 5px;
    padding-top: 2px;
    background: url('~@/assets/images/select_bg.png') no-repeat bottom/100px 22px;
  }
}
</style>
