<template>
    <div class="intro">
        <ul>
            <li>
                <h3>个人简介</h3>
                <p>{{infoObj.vdesc||''}}</p>
                <!-- <p>辣椒落花不坐果是什么原因辣椒落花不坐果是什么原因辣椒落花不坐果是什么原因辣椒落花不坐果是什么原因辣椒落花不坐果是什么原因辣椒落花不坐果是什么原因辣椒落花不坐果是什么原因辣椒落花不坐果是什么原因辣椒落花不坐果是什么原因辣椒落花不坐果是什么原因辣椒落花不坐果是什么原因辣椒落花不坐果是什么原因辣椒落花不坐果是什么原因辣椒落花不坐果是什么原因辣椒落花不坐果是什么原因辣椒落花不坐果是什么原因辣椒落花不坐果是什么原因辣椒落花不坐果是什么原因辣椒落花不坐果是什么原因辣椒落花不坐果是什么原因辣椒落花不坐果是什么原因</p> -->
            </li>
            <li v-for="item in expertDetail" :key="item.id">
                <h3>{{item.showTitle}}</h3>
                <p>{{item.sdesc}}</p>
            </li>
            <!-- <li>
                <h3>履历</h3>
                <p>
                    
                </p>
            </li> -->
            <!-- <li>
                <h3>获奖</h3>
                <p>辣椒落花不坐果是什么原因辣椒落花不坐果是什么原因辣椒落花不坐果是什么原因辣椒落花不坐果是什么原因辣椒落花不坐果是什么原因辣椒落花不坐果是什么原因辣椒落花不坐果是什么原因辣椒落花不坐果是什么原因辣椒落花不坐果是什么原因辣椒落花不坐果是什么原因辣椒落花不坐果是什么原因辣椒落花不坐果是什么原因辣椒落花不坐果是什么原因辣椒落花不坐果是什么原因辣椒落花不坐果是什么原因辣椒落花不坐果是什么原因辣椒落花不坐果是什么原因辣椒落花不坐果是什么原因辣椒落花不坐果是什么原因辣椒落花不坐果是什么原因辣椒落花不坐果是什么原因</p>
            </li> -->
        </ul>
    </div>
</template>

<script>
export default {
    props:{
        infoObj:{
            type:Object,
            default:()=>({})
        }
    },
    data(){
        return {

        }
    },
    computed:{
        expertDetail(){
            if (this.infoObj.expertDetail) {
                return this.infoObj.expertDetail.filter(item=>{
                    return item.showTitle
                })
            }else{
                return []
            }
        }
    }
}
</script>

<style lang='scss' scoped>
    .intro{
        // border: 1px solid #f00;
        height: 100%;
        ul{
            li{
                padding-bottom: 20px;
                box-sizing: border-box;
                h3{
                    height: 25px;
                    line-height: 25px;
                    width: 415px;
                    padding-left: 40px;
                    box-sizing: border-box;
                    font-size: 20px;
                    margin-bottom: 20px;
                    background: url('~@/assets/images/dialog/titlebg.png') no-repeat center/100% 100%;
                }
                p{
                    font-size: 16px;
                    line-height: 1.8;
                    text-indent: 2em;
                    padding: 0 30px;
                    box-sizing: border-box;
                    color: #e5f2ff;
                }
            }
        }
    }
</style>