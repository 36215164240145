<template>
    <div>
        <!-- 视频详情 -->
        <base-dialog 
            width="1000px" 
            label="视频详情"
            v-show="dialogFlag"
            :show-close="true"
            @closeDialog="closeDialog"
        >
            <div class="video_detail">
                <el-scrollbar style="height:100%;">
                    <div class="wrap">
                        <video controls ref="myVideo">
                            <source :src="infoObj.videourl?wjImageUrl+infoObj.videourl:''" type="video/mp4">
                            您的浏览器不支持Video标签。
                        </video>
                        <div class="info">
                            <h3>{{infoObj.title||''}}</h3>
                            <div class="info_wrap">
                                <label>讲师：</label>
                                <span>{{infoObj.username||''}}</span>
                                <span>{{infoObj.videosource||''}}</span>
                                <label>播放量：</label>
                                <span>{{infoObj.hits||''}}</span>
                                <label>上传时间：</label>
                                <span>{{infoObj.screatetime||''}}</span>
                            </div>
                        </div>
                        <div class="kc_info">
                            <ul class="left">
                                <li :class="{active:selectIndex=='js'}" @click="selectMenu('js')">课程介绍</li>
                                <li :class="{active:selectIndex=='dg'}" @click="selectMenu('dg')">课程大纲</li>
                            </ul>
                            <div class="right_content">
                                <p v-html="content"></p>
                                <!-- <p>辣椒落花不坐果是什么原因辣椒落花不坐果是什么原因辣椒落花不坐果是什么原因辣椒落花不坐果是什么原因辣椒落花不坐果是什么原因辣椒落花不坐果是什么原因辣椒落花不坐果是什么原因辣椒落花不坐果是什么原因辣椒落花不坐果是什么原因辣椒落花不坐果是什么原因辣椒落花不坐果是什么原因辣椒落花不坐果是什么原因辣椒落花不坐果是什么原因辣椒落花不坐果是什么原因辣椒落花不坐果是什么原因辣椒落花不坐果是什么原因辣椒落花不坐果是什么原因辣椒落花不坐果是什么原因辣椒落花不坐果是什么原因辣椒落花不坐果是什么原因辣椒落花不坐果是什么原因</p> -->
                            </div>
                        </div>
                    </div>
                </el-scrollbar>
            </div>
        </base-dialog>
    </div>
</template>

<script>
import BaseDialog from '@/components/dialog/baseDialog' //弹框
// import { wjImageUrl } from '@/utils/config'
export default {
    components:{BaseDialog},
    data(){
        return {
            dialogFlag:false,
            infoObj:{},
            wjImageUrl,
            selectIndex:'js',
            // content:'',//内容
        }
    },
    computed:{
        content(){
            if (this.selectIndex=='js') {
                return this.infoObj.coursedesc
            }else if (this.selectIndex=='dg') {
                return this.infoObj.coursesyllabus
            }
        }
    },
    methods:{
        closeDialog(){//关闭弹框
            this.dialogFlag=false
            this.selectIndex='js'
        },
        show(obj){//
            this.infoObj=obj
            this.dialogFlag=true
            this.myPlyer = this.$refs.myVideo
            this.myPlyer.load();
        },
        selectMenu(type){//切换
            this.selectIndex=type
            // if (type=='js') {
            //     this.content=this.infoObj.coursedesc
            // }else if (type=='dg') {
            //     this.content=this.infoObj.coursesyllabus
            // }
        }
    }
}
</script>

<style lang='scss' scoped>
    .video_detail{
        // border: 5px solid #f00;
        padding: 35px 45px 25px;
        box-sizing: border-box;
        height: 100%;
        overflow: hidden;
        .wrap{
            // border: 5px solid #0f0;
            >video{
                width: 100%;
                height: 530px;
                padding: 0 10px;
                box-sizing: border-box;
                // border: 1px solid #0f0;
            }
            .info{
                // border: 1px solid #0f0;
                margin:20px 0 15px;
                >h3{
                    font-size: 24px;
                }
                .info_wrap{
                    font-size: 16px;
                    margin-top: 10px;
                    span{
                        color: #10c4ff;
                        margin-right: 30px;
                    }
                }
            }
            .kc_info{
                // border: 1px solid #0f0;
                background-color: rgba(1,43,119,.58);
                display: flex;
                padding: 25px 20px;
                box-sizing: border-box;
                .left{
                    width: 150px;
                    li{
                        width: 100%;
                        height: 30px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background: url('~@/assets/images/dialog/wlwsb_menu.png') no-repeat center/100% 100%;
                        margin-top: 25px;
                        cursor: pointer;
                    }
                    li:first-child{
                        margin-top: 0;
                    }
                    .active{
                        background: url('~@/assets/images/dialog/wlwsb_menu.active.png') no-repeat center/100% 100%;
                    }
                }
                .right_content{
                    // border: 1px solid #0f0;
                    flex: 1;
                    margin-left: 25px;
                    img{
                        max-width: 100%;
                    }
                }
            }
        }
    }
</style>